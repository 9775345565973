import React, { useState } from 'react';
import { ThumbsUp, ThumbsDown, Send } from 'lucide-react';
import RatingOption from './RatingOption';

const InterviewFeedback: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedRating, setSelectedRating] = useState<string | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);
  };

  const ratingOptions = [
    { value: 'excellent', label: 'Excellent', icon: ThumbsUp, color: 'emerald' },
    { value: 'good', label: 'Good', icon: ThumbsUp, color: 'blue' },
    { value: 'fair', label: 'Fair', icon: ThumbsDown, color: 'amber' },
    { value: 'poor', label: 'Poor', icon: ThumbsDown, color: 'red' },
  ];

  return (
    <div className="w-full flex items-center justify-center">
      {isSubmitted ? (
        <div className="w-full max-w-xl bg-white rounded-2xl shadow-xl border border-gray-100 p-8 text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-3">Thank You!</h2>
          <p className="text-gray-600 mb-2">Thank you for completing the interview.</p>
          <p className="text-gray-600 mb-2">Your session has been successfully concluded.</p>
          <p className="text-gray-600">You may now close this window.</p>
        </div>
      ) : (
        <div className="w-full max-w-xl bg-white rounded-2xl shadow-xl border border-gray-100 items-center justify-center">
          <div className="p-3">
            <h2 className="text-3xl font-bold text-gray-800 text-center mb-3">
              How was your interview?
            </h2>
          </div>

          <form onSubmit={handleSubmit} className="p-8 pt-0 space-y-8">
            <div className="space-y-6">
              <label className="block text-lg font-semibold text-gray-800 text-center">
                Rate your overall experience
              </label>
              <div className="grid grid-cols-2 gap-6">
                {ratingOptions.map(option => (
                  <RatingOption
                    key={option.value}
                    option={option}
                    isSelected={selectedRating === option.value}
                    onSelect={setSelectedRating}
                  />
                ))}
              </div>
            </div>

            <div className="space-y-3">
              <label htmlFor="feedback" className="block text-lg font-semibold text-gray-800 text-center">
                Share your thoughts
              </label>
              <textarea
                id="feedback"
                className="w-full min-h-[150px] p-4 rounded-xl resize-none border-2 focus:outline-none focus:ring-2 focus:ring-black focus:border-black transition-colors text-gray-700 placeholder-gray-400"
                placeholder="What went well? What could be improved? Your insights are valuable to us..."
              />
            </div>

            <button
              type="submit"
              className="w-full flex items-center justify-center py-4 px-6 rounded-xl text-white font-semibold bg-black hover:bg-gray-900 transform transition-all duration-200 hover:scale-[1.02] focus:ring-4 focus:ring-gray-200"
            >
              <Send className="mr-2 h-5 w-5" />
              Submit Feedback
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default InterviewFeedback;