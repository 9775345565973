import React from 'react';
import { RatingOptionProps } from '../types/types';

const getOptionClasses = (optionColor: string, isSelected: boolean) => {
  const baseClasses =
    'flex flex-col items-center justify-between rounded-xl border-2 p-6 cursor-pointer transition-all duration-200 group';
  if (isSelected) {
    switch (optionColor) {
      case 'emerald':
        return `${baseClasses} bg-emerald-50 border-emerald-500`;
      case 'blue':
        return `${baseClasses} bg-blue-50 border-blue-500`;
      case 'amber':
        return `${baseClasses} bg-amber-50 border-amber-500`;
      case 'red':
        return `${baseClasses} bg-red-50 border-red-500`;
      default:
        return baseClasses;
    }
  } else {
    switch (optionColor) {
      case 'emerald':
        return `${baseClasses} hover:bg-emerald-50 hover:border-emerald-500`;
      case 'blue':
        return `${baseClasses} hover:bg-blue-50 hover:border-blue-500`;
      case 'amber':
        return `${baseClasses} hover:bg-amber-50 hover:border-amber-500`;
      case 'red':
        return `${baseClasses} hover:bg-red-50 hover:border-red-500`;
      default:
        return baseClasses;
    }
  }
};

const RatingOption: React.FC<RatingOptionProps> = ({ option, isSelected, onSelect }) => {
  const Icon = option.icon;
  return (
    <label
      onClick={() => onSelect(option.value)}
      className={getOptionClasses(option.color, isSelected)}
    >
      <input
        type="radio"
        name="rating"
        value={option.value}
        className="sr-only"
        checked={isSelected}
        onChange={() => onSelect(option.value)}
      />
      <Icon
        className={`mb-4 h-8 w-8 ${
          isSelected
            ? `text-${option.color}-500`
            : `text-gray-400 group-hover:text-${option.color}-500`
        }`}
      />
      <span
        className={`text-lg font-medium ${
          isSelected
            ? `text-${option.color}-700`
            : `text-gray-600 group-hover:text-${option.color}-700`
        }`}
      >
        {option.label}
      </span>
    </label>
  );
};

export default RatingOption;
